<template>
	<div class="form-container">
		<!-- General info -->
		<CRow>
			<CCol md="12">
				<h2 class="typo-h4 position-relative">
					General info
					<span class="title-caption typo-caption color-black-45">Flash sale ID: <span class="typo-caption color-black-45">{{ formData.id || 'Generate after creates' }}</span></span>
				</h2>
			</CCol>
			<CCol md="12" class="mt-2">
				<CInput
					v-model.trim="$v.formData.name.$model"
					:is-valid="!$v.formData.name.$error && null"
					label="Flash sale name (for back office)*"
					placeholder="e.g. Mid Year Sale"
					invalid-feedback="Field Length: 1 - 255 characters"
					@blur="generateSlug"
				/>
			</CCol>
			<CCol md="12" class="mt-2">
				<CInput
					v-model.trim="$v.formData.slug.$model"
					:is-valid="!$v.formData.slug.$error && null"
					:invalid-feedback="slugErrors"
					:description="slugHelperText"
					label="Slug*"
				/>
			</CCol>
			<CCol md="12">
				<div class="form-group form-group-switch mt-1">
					<label class="toggle-label" for="status">Flash sale status</label>
					<CSwitch
						id="status"
						:checked.sync="formData.status"
						variant="3d"
						size="sm"
						class="switch-custom"
						color="success"
						@update:checked="handleChangeStatus"
					/>
					<span>{{ formData.status ? 'Active' : 'Inactive' }}</span>
				</div>
			</CCol>
			<CCol md="12" class="mt-4">
				<hr>
			</CCol>
		</CRow>
		<!-- End General info -->
		<!-- Active -->
		<CRow class="form-group mt-4">
			<CCol md="12">
				<h2 class="typo-h4">
					Active dates
				</h2>
			</CCol>
			<CCol md="4" class="mt-2">
				<BaseInputDate
					v-model="$v.formData.startDate.$model"
					:disabled="!isEnableStartActiveDate"
					:is-valid="!$v.formData.startDate.$error"
					:min-date="minActiveDate"
					label="Start date*"
					invalid-feedback="Start date is required"
					placeholder="Select date"
					@input="handleActiveStartDateChange"
				>
					<template #append-content>
						<CIcon class="cil-calendar" name="cil-calendar" />
					</template>
				</BaseInputDate>
			</CCol>
			<CCol md="2" class="mt-2">
				<BaseInputTime
					v-model="$v.formData.startTime.$model"
					:disabled="!isEnableStartActiveDate"
					:is-valid="!$v.formData.startTime.$error"
					:invalid-feedback="errorStartTime"
					label="Start time"
				/>
			</CCol>

			<CCol md="4" class="mt-2">
				<BaseInputDate
					v-model="$v.formData.endDate.$model"
					:disabled="!isEnableEndActiveDate || $v.formData.startDate.$invalid"
					:is-valid="!$v.formData.endDate.$error"
					:min-date="formData.startDate"
					label="End date*"
					invalid-feedback="End date is required"
					placeholder="Select date"
					data-test-id="end-date"
				>
					<template #append-content>
						<CIcon class="cil-calendar" name="cil-calendar" />
					</template>
				</BaseInputDate>
			</CCol>
			<CCol md="2" class="mt-2">
				<BaseInputTime
					v-model="$v.formData.endTime.$model"
					:disabled="!isEnableEndActiveDate"
					:is-valid="!$v.formData.endTime.$error"
					:invalid-feedback="errorEndTime"
					label="End time"
				/>
			</CCol>

			<CCol md="12" class="mt-4">
				<hr>
			</CCol>
		</CRow>
		<!-- End Active -->
		<!-- Customer group -->
		<CRow>
			<CCol md="12">
				<FlashSaleCustomerGroup
					:customer-groups="formData.customerGroups"
					@update:customerGroups="handleCustomerGroupChange"
				/>
			</CCol>
		</CRow>
		<!-- End Customer group -->
		<!-- Exclude promotion condition -->
		<CRow>
			<CCol md="12">
				<FlashSaleExcludePromotionCondition
					:promotions="formData.excludePromotions"
					:exclude-ids="[formData.id]"
					@update:excludePromotions="handleExcludePromotionChange"
				/>
			</CCol>
		</CRow>
		<!-- End Exclude promotion condition -->
		<!-- Remarks -->
		<CRow v-if="isEditMode">
			<CCol md="12">
				<FlashSaleRemarks
					:remarks.sync="formData.remarks"
					:promotion-id="formData.id"
				/>
			</CCol>
		</CRow>
		<!-- End Remarks -->
	</div>
</template>

<script>
import dayjs from 'dayjs';
import { required, maxLength, minValue, helpers } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import FlashSaleCustomerGroup from '@/components/FlashSaleCustomerGroup.vue';
import FlashSaleExcludePromotionCondition from '@/components/FlashSaleExcludePromotionCondition.vue';
import FlashSaleRemarks from '@/components/FlashSaleRemarks.vue';

import { FLASHSALE_PERIODS_STATUSES } from '../enums/flashSales';
import { after, timeFormat } from '../assets/js/validators';
import slugRegex from '../regex/slug';

const slugify = require('slugify');

export default {
	name: 'FlashSaleStepGeneralInfo',

	components: {
		FlashSaleCustomerGroup,
		FlashSaleExcludePromotionCondition,
		FlashSaleRemarks,
	},

	validations() {
		let schema = {};
		const base = {
			name: {
				required,
				maxLength: maxLength(255),
			},
			slug: {
				required,
				format: helpers.regex('slug', slugRegex),
			},
			startDate: {
				required,
			},
			endDate: {
				required,
			},
			startTime: {
				required,
				timeFormat,
			},
			endTime: {
				required,
				timeFormat,
			},
			...(
				this.formData.overridePeriod
					? {
						overridePeriodValue: {
							required,
							minValue: minValue(1),
						},
					}
					: null
			),
		};

		// If start date same as end date, we need to validation time.
		const isSameDateSchema = {
			endTime: {
				required,
				timeFormat,
				after: after('startTime', 'endTime'),
			},
		};

		if (this.formData.startDate && this.formData.endDate && dayjs(this.formData.startDate).isSame(dayjs(this.formData.endDate))) {
			schema = {
				formData: {
					...base,
					...isSameDateSchema,
				},
			};
		} else {
			schema = {
				formData: {
					...base,
				},
			};
		}

		return schema;
	},

	mixins: [validationMixin],

	props: {
		defaultData: {
			type: Object,
			default: null,
		},
		isEditMode: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		const {
			id,
			name = null,
			status = false,
			slug = null,
			period,
			startDate = null,
			startTime = '00:00',
			endDate = null,
			endTime = '23:59',
			customerGroups = [],
			excludePromotions = [],
			remarks,
		} = this.defaultData || {};

		return {
			minActiveDate: new Date(),
			period,
			formData: {
				id,
				name,
				status,
				slug,
				startDate,
				startTime,
				endDate,
				endTime,
				customerGroups,
				excludePromotions,
				remarks,
			},
		};
	},
	computed: {
		errorStartTime() {
			if (!this.$v.formData.startTime.required) {
				return this.$t('global.error.required');
			}

			if (!this.$v.formData.startTime.validTime) {
				return 'Time not valid';
			}

			return null;
		},
		errorEndTime() {
			if (!this.$v.formData.endTime.required) {
				return this.$t('global.error.required');
			}

			if (!this.$v.formData.endTime.validTime) {
				return 'Time not valid';
			}

			if (!this.$v.formData.endTime.after) {
				return this.$t('global.error.timeAfter', { time: this.formData.startTime });
			}

			return null;
		},
		isEnableStartActiveDate() {
			return ![FLASHSALE_PERIODS_STATUSES.ON_GOING, FLASHSALE_PERIODS_STATUSES.EXPIRED].includes(this.period);
		},
		isEnableEndActiveDate() {
			return ![FLASHSALE_PERIODS_STATUSES.EXPIRED].includes(this.period);
		},
		slugHelperText() {
			if (this.$v.formData.slug.$error) {
				return '';
			}
			return this.$t('global.slug.slugFormat');
		},
		slugErrors() {
			if (!this.$v.formData.slug.required) {
				return this.$t('global.error.required');
			}

			if (!this.$v.formData.slug.slugFormat) {
				return this.$t('global.slug.invalid');
			}

			return null;
		},
	},
	watch: {
		formData: {
			deep: true,
			handler(data) {
				this.$emit(
					'update:is-valid',
					{
						valid: !this.$v.$invalid,
						data,
					},
				);
			},
		},
	},
	methods: {
		handleChangeStatus(value) {
			this.formData.status = value;
		},

		handleActiveStartDateChange(value) {
			if (!this.formData.endDate || dayjs(value).isAfter(dayjs(this.formData.endDate))) {
				this.formData.endDate = value;
			}
		},

		handleCustomerGroupChange(list) {
			this.formData.customerGroups = list;
		},

		handleExcludePromotionChange(list) {
			this.formData.excludePromotions = list;
		},

		generateSlug() {
			if (!this.formData.slug) {
				this.formData.slug = slugify(this.formData.name, {
					lower: true,
					strict: true,
				});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.form-container {
		margin-top: rem(80);

		.title-caption {
			position: absolute;
			right: 0;
			bottom: rem(4);
			font-weight: $font-weight-semi-bold;

			span {
				font-weight: $font-weight-bold;
			}
		}
	}
</style>
