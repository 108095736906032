<template>
	<div>
		<CRow>
			<CCol md="12">
				<h2 class="typo-h4 position-relative">Exclude</h2>
			</CCol>
			<CCol md="12">
				<div class="d-block mb-3">
					<label class="mb-0">
						<h2 class="typo-label">
							Exclude customer group
						</h2>
						<p class="typo-caption color-black-45">Set this flash sale to unavailable for specific customer group.</p>
					</label>
					<CButton
						type="button"
						class="btn btn-add float-right"
						color="secondary"
						data-test-id="button-add"
						@click="$refs['modal-customer-group'].open()"
					>
						{{ customerGroupTitle }}
					</CButton>
				</div>
			</CCol>
		</CRow>
		<CRow>
			<CCol v-if="!customerGroups.length" class="mb-3" lg="12">
				<BaseNoItemBanner
					text="This flash sale is available for all customer groups."
				/>
			</CCol>
			<CCol v-else lg="12">
				<ul class="customer-group-list">
					<li v-for="item in customerGroups" :key="item.id" class="list-item typo-body-2">
						{{ item.name }}
					</li>
				</ul>
			</CCol>
			<CCol md="12" class="mt-4">
				<hr>
			</CCol>
		</CRow>

		<ModalCustomerGroup
			ref="modal-customer-group"
			:title="excludeCustomerGroupTitle"
			:list="customerGroups"
			@onConfirm="handleCustomerGroupListChange"
		/>
	</div>
</template>

<script>
import ModalCustomerGroup from '@/components/ModalCustomerGroup.vue';

export default {
	name: 'FlashSaleCustomerGroup',
	components: {
		ModalCustomerGroup,
	},
	props: {
		customerGroups: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		customerGroupTitle() {
			return `${this.customerGroups && this.customerGroups.length ? 'Edit' : 'Add'} customer group`;
		},
		excludeCustomerGroupTitle() {
			return `${this.customerGroups && this.customerGroups.length ? 'Edit' : 'Add'} exclude customer group`;
		},
	},
	methods: {
		handleCustomerGroupListChange(list) {
			let transformList = [];
			if (list && Array.isArray(list)) {
				transformList = list.map(({ id, name }) => ({ id, name }));
			}
			this.$emit('update:customerGroups', transformList);
		},
	},
};
</script>

<style lang="scss" scoped>
	.customer-group-list {
		overflow-y: auto;
		min-height: rem(60);
		max-height: rem(468);
		padding: rem(20) rem(16) rem(8);
		border-radius: rem(4);
		border: solid 1px $color-gray-400;
		list-style: none;

		.list-item {
			border-radius: rem(4);
			background-color: $color-gray-300;
			color: $color-black-90;
			display: inline-block;
			padding: 0 rem(6);
			margin-right: rem(12);
			margin-bottom: rem(12);
		}
	}
</style>
