<template>
	<div>
		<CRow>
			<CCol md="12">
				<div class="d-block mt-4 mb-3">
					<label class="mb-0">
						<h2 class="typo-label">Exclude promotions</h2>
						<p class="typo-caption color-black-45">
							Set this flash sale to unavailable for specific promotion.
						</p>
					</label>
					<CButton
						type="button"
						class="btn btn-add float-right"
						color="secondary"
						data-test-id="button-add"
						@click="$refs['modal-promotion'].open()"
					>
						{{ excludePromotionTitle }}
					</CButton>
				</div>
			</CCol>
		</CRow>
		<CRow>
			<CCol v-if="!promotions.length" class="mb-3" lg="12">
				<BaseNoItemBanner
					text="This flash sale can be used with all promotions."
				/>
			</CCol>
			<CCol v-else lg="12">
				<header class="d-flex mb-1 promotion-box-list">
					<span
						v-for="head in promotionsTableFields"
						:key="head.key"
						:class="[
							'label',
							head.class ? `promotion-box-${head.key}` : '',
						]"
					>
						{{ head.label }}
					</span>
				</header>
				<ul class="list-unstyled border rounded-sm border-secondary px-3 py-3">
					<li
						v-for="(item, index) in promotions"
						:key="index"
						class="d-flex promotion-box-data"
					>
						<div
							v-for="data in promotionsTableFields"
							:key="data.key"
							:class="`promotion-box-${data.key}`"
						>
							<template v-if="data.key === 'title'">
								{{ item[data.key].name }}
							</template>
							<template v-else-if="data.key === 'type'">
								<div class="name">
									{{ item[data.key].name }}
								</div>
								<div class="caption">
									{{ item[data.key].reward }}
								</div>
							</template>
							<template v-else>
								{{ item[data.key] }}
							</template>
						</div>
					</li>
				</ul>
			</CCol>
			<CCol md="12" class="mt-4">
				<hr>
			</CCol>
		</CRow>

		<ModalPromotion
			ref="modal-promotion"
			:title="excludePromotionTitle"
			:exclude-ids="excludeIds"
			:list="promotions"
			@onConfirm="handleExcludePromotionListChange"
		/>
	</div>
</template>


<script>
import ModalPromotion from '@/components/ModalPromotion.vue';

import { PROMOTIONS_TABLE_FIELDS } from '../enums/promotions';

export default {
	name: 'FlashSaleExcludePromotionCondition',
	components: {
		ModalPromotion,
	},
	props: {
		excludeIds: {
			type: Array,
			default: null,
		},
		promotions: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		promotionsTableFields() {
			const pickedKeys = ['id', 'title', 'type'];
			return PROMOTIONS_TABLE_FIELDS.filter((item) => pickedKeys.includes(item.key));
		},
		excludePromotionTitle() {
			return `${this.promotions && this.promotions.length ? 'Edit' : 'Add'} exclude promotion`;
		},
	},
	methods: {
		handleExcludePromotionListChange(list) {
			let transformList = [];
			if (list && Array.isArray(list)) {
				transformList = list.map(({ id, title, type }) => ({ id, title, type }));
			}
			this.$emit('update:excludePromotions', transformList);
		},
	},
};
</script>

<style lang="scss" scoped>
	.promotion-box {
		&.is-invalid {
			border: 1px solid $color-alert;
			border-radius: rem(4);
		}

		&-list {
			// rem(16) + border 1 px
			padding: 0 rem(17);
		}

		// .promotion-box-id
		&-id {
			width: rem(32);
		}

		// .promotion-box-title
		&-title {
			flex: 3;
		}

		// .promotion-box-type
		&-type {
			flex: 1;
		}

		// .promotion-box-data
		&-data {
			font-size: rem($font-size-label);
			margin-bottom: rem(16);

			&:last-child {
				margin-bottom: 0;
			}

			.caption {
				font-size: rem($font-size-caption);
				font-weight: $font-weight-bold;
				color: $color-black-45;
			}
		}
	}

	.promotion-list {
		overflow-y: auto;
		min-height: rem(60);
		max-height: rem(468);
		padding: rem(20) rem(16);
		border-radius: rem(4);
		border: solid 1px $color-gray-400;
		list-style: none;

		.list-item {
			border-radius: rem(4);
			background-color: $color-gray-300;
			color: $color-black-90;
			display: inline-block;
			padding: 0 rem(6);
			margin-right: rem(12);
			margin-bottom: rem(12);
		}
	}
</style>
