<template>
	<CModal
		:show.sync="isShow"
		:close-on-backdrop="false"
		class="modal-duplicate-period"
		centered
	>
		<template #header-wrapper>
			<ModalHeaderWrapper
				:title="title"
				@onClose="close"
			/>
		</template>
		<template #default>
			<p class="mb-0">{{ description }}</p>
		</template>
		<template #footer>
			<CButton
				color="primary"
				@click="isShow = false"
			>
				{{ primaryButtonText }}
			</CButton>
		</template>
	</CModal>
</template>

<script>
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';

export default {
	name: 'BaseModalDuplicatePeriod',
	components: {
		ModalHeaderWrapper,
	},
	props: {
		title: {
			type: String,
			default: null,
		},
		description: {
			type: String,
			default: null,
		},
		primaryButtonText: {
			type: String,
			default: 'OK',
		},
	},
	data() {
		return {
			isShow: false,
		};
	},
	methods: {
		open() {
			this.isShow = true;
		},
		close() {
			this.isShow = false;
		},
	},
};
</script>
