<template>
	<div class="form-container">
		<div>
			<h4>Store front visibility (Website)</h4>
			<hr class="my-5">
		</div>
		<section class="flash-sale-banner-container">
			<form class="mt-4 mb-3" @submit.prevent="handleSubmit">
				<BannerContentWrapper
					ref="content"
					:has-banner-link="false"
					desktop-img="/assets/images/flash-sale-background-desktop.svg"
					mobile-img="/assets/images/flash-sale-background-mobile.svg"
					@update:bannerLink="bannerLink = $event"
				>
					<template #title>
						Background flash sale
					</template>
					<template #description>
						<p class="typo-body-2 color-black-45">Upload 2 sizes of banner one for desktop and another one for mobile.</p>
					</template>
					<template #detail>
						Background flash sale will be displayed behind the flash sale section. Please avoid to add information on the bottom of banner to keep spacing for time counter and call to action.
					</template>
					<CRow class="mt-4">
						<CCol>
							<BaseMediaSelectorBox
								ref="flashSaleBackgroundDesktopBanner"
								v-model="formData.flashSaleBackgroundDesktopBanner"
								title="Desktop*"
								no-image-text="No banner"
								show-helper
								width="1440"
								height="768"
								required
							/>
						</CCol>
						<CCol>
							<BaseMediaSelectorBox
								ref="flashSaleBackgroundMobileBanner"
								v-model="formData.flashSaleBackgroundMobileBanner"
								title="Mobile*"
								no-image-text="No banner"
								show-helper
								width="768"
								height="768"
								required
							/>
						</CCol>
					</CRow>
				</BannerContentWrapper>

				<BannerContentWrapper
					ref="content"
					:has-banner-link="false"
					desktop-img="/assets/images/flash-sale-icon-desktop.svg"
					mobile-img="/assets/images/flash-sale-icon-mobile.svg"
					@update:bannerLink="bannerLink = $event"
				>
					<template #title>
						Icon flash sale
					</template>
					<template #description>
						<p class="typo-body-2 color-black-45">Upload 2 sizes of banner one for desktop and another one for mobile.</p>
					</template>
					<template #detail>
						Icon flash sale will be displayed in flash sale section. Please avoid to add information on the bottom of banner to keep spacing for time counter and call to action.
					</template>

					<CRow class="mt-4">
						<CCol>
							<BaseMediaSelectorBox
								ref="flashSaleIconDesktopBanner"
								v-model="formData.flashSaleIconDesktopBanner"
								title="Desktop*"
								no-image-text="No banner"
								show-helper
								width="184"
								height="36"
							/>
						</CCol>
						<CCol>
							<BaseMediaSelectorBox
								ref="flashSaleIconMobileBanner"
								v-model="formData.flashSaleIconMobileBanner"
								title="Mobile*"
								no-image-text="No banner"
								show-helper
								width="134"
								height="28"
							/>
						</CCol>
					</CRow>
				</BannerContentWrapper>

				<BannerContentWrapper
					ref="content"
					:has-banner-link="false"
					desktop-img="/assets/images/flash-sale-time-counter-desktop.svg"
					mobile-img="/assets/images/flash-sale-time-counter-mobile.svg"
				>
					<template #title>
						Time counter and font color flash sale banner
					</template>
					<template #detail>
						Time counter will be displayed in flash sale section. Time counter and font color flash sale homepage will be displayed in flash sale section. Please avoid adding information on the alpha of color.
					</template>

					<CRow class="mt-4">
						<CCol md="5">
							<div class="mb-3">
								<Label>Select background color*</Label>
								<BaseColorPicker v-model="formData.counterBackgroundColor" />
							</div>

							<div>
								<Label>Select font color*</Label>
								<BaseColorPicker v-model="formData.counterFontColor" />
							</div>
						</CCol>

						<CCol md="7">
							<Label>Preview</Label>
							<PreviewFlashSaleTimer :font-color="formData.counterFontColor" :background-color="formData.counterBackgroundColor" />
						</CCol>
					</CRow>
				</BannerContentWrapper>

				<BannerContentWrapper
					ref="content"
					:has-banner-link="false"
					desktop-img="/assets/images/flash-sale-list-desktop.svg"
					mobile-img="/assets/images/flash-sale-list-mobile.svg"
					@update:bannerLink="bannerLink = $event"
				>
					<template #title>
						Flash sale banner (Product list)
					</template>
					<template #description>
						<p class="typo-body-2 color-black-45">Upload 2 sizes of banner one for desktop and another one for mobile.</p>
					</template>
					<template #detail>
						Flash sale banner will be displayed on the top of flash sale page. Please avoid to add information on the bottom of banner to keep spacing for time counter and call to action.
					</template>
					<CRow class="mt-4">
						<CCol>
							<BaseMediaSelectorBox
								ref="flashSaleDesktopBanner"
								v-model="formData.flashSaleDesktopBanner"
								title="Desktop"
								no-image-text="No banner"
								show-helper
								width="2,352"
								height="640"
							/>
						</CCol>
						<CCol>
							<BaseMediaSelectorBox
								ref="flashSaleMobileBanner"
								v-model="formData.flashSaleMobileBanner"
								title="Mobile"
								no-image-text="No banner"
								show-helper
								width="656"
								height="240"
							/>
						</CCol>
					</CRow>
				</BannerContentWrapper>

				<BannerContentWrapper
					ref="content"
					:has-banner-link="false"
					mobile-img="/assets/images/flash-sale-list-mobile.svg"
					@update:bannerLink="bannerLink = $event"
				>
					<template #title>
						Background flash sale banner product list for mobile application
					</template>
					<template #description>
						<p class="typo-body-2 color-black-45">Upload banner for mobile.</p>
					</template>
					<template #detail>
						Flash sale product list banner will be displayed in flash sale section. Please avoid to add infimmation on the bottom of banner to keep spacing for time counter and call to action.
					</template>

					<BaseMediaSelectorBox
						v-model="formData.flashSaleBackgroundMoileAppBanner"
						title="Mobile"
						no-image-text="No banner"
						show-helper
						width="640"
						height="100"
						class="mt-2"
					/>
				</BannerContentWrapper>


				<BannerContentWrapper
					ref="content"
					:has-banner-link="false"
					mobile-img="/assets/images/flash-sale-list-mobile.svg"
					@update:bannerLink="bannerLink = $event"
				>
					<template #title>
						Icon flash sale banner product list for mobile application
					</template>
					<template #description>
						<p class="typo-body-2 color-black-45">Upload banner for mobile.</p>
					</template>
					<template #detail>
						Flash sale product list banner will be displayed in flash sale section. Please avoid to add infimmation on the bottom of banner to keep spacing for time counter and call to action.
					</template>

					<BaseMediaSelectorBox
						v-model="formData.flashSaleIconMoileAppBanner"
						title="Mobile"
						no-image-text="No banner"
						show-helper
						width="365"
						height="152"
						class="mt-2"
					/>
				</BannerContentWrapper>

				<FlashSaleContent
					:description-top.sync="formData.descriptionTop"
					:description-bottom.sync="formData.descriptionBottom"
				/>
			</form>
		</section>
	</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import BannerContentWrapper from '@/components/BannerContentWrapper.vue';
import BaseMediaSelectorBox from '@/components/BaseMediaSelectorBox.vue';
import FlashSaleContent from '@/components/FlashSaleContent.vue';
import PreviewFlashSaleTimer from '@/components/PreviewFlashSaleTimer.vue';
import { hexColorFormat } from '../assets/js/validators';

export default {
	name: 'BannerHeroForm',
	components: {
		BannerContentWrapper,
		BaseMediaSelectorBox,
		FlashSaleContent,
		PreviewFlashSaleTimer,
	},
	validations: {
		formData: {
			flashSaleBackgroundDesktopBanner: {
				required,
			},
			flashSaleBackgroundMobileBanner: {
				required,
			},
			flashSaleIconDesktopBanner: {
				required,
			},
			flashSaleIconMobileBanner: {
				required,
			},
			counterFontColor: {
				required,
				format: hexColorFormat,
			},
			counterBackgroundColor: {
				required,
				format: hexColorFormat,
			},
		},
	},
	props: {
		defaultData: {
			type: Object,
			default: null,
		},
		descriptionBottom: {
			type: String,
			default: null,
		},
		descriptionTop: {
			type: String,
			default: null,
		},
	},
	data() {
		const {
			flashSaleBackgroundDesktopBanner = null,
			flashSaleBackgroundMobileBanner = null,
			flashSaleIconDesktopBanner = null,
			flashSaleIconMobileBanner = null,
			flashSaleDesktopBanner = null,
			flashSaleMobileBanner = null,
			flashSaleBackgroundMoileAppBanner = null,
			flashSaleIconMoileAppBanner = null,
			counterFontColor = '#FFFFFF',
			counterBackgroundColor = '#000000',
			descriptionBottom = null,
			descriptionTop = null,
		} = this.defaultData || {};

		return {
			formData: {
				flashSaleBackgroundDesktopBanner,
				flashSaleBackgroundMobileBanner,
				flashSaleIconDesktopBanner,
				flashSaleIconMobileBanner,
				flashSaleDesktopBanner,
				flashSaleMobileBanner,
				flashSaleBackgroundMoileAppBanner,
				flashSaleIconMoileAppBanner,
				counterFontColor,
				counterBackgroundColor,
				descriptionBottom,
				descriptionTop,
			},
		};
	},
	watch: {
		formData: {
			deep: true,
			handler(data) {
				this.$emit(
					'update:is-valid',
					{
						valid: !this.$v.$invalid,
						data,
					},
				);
			},
		},
	},
};
</script>

<style lang="scss" scoped>
	.form-container {
		margin-top: rem(80);

		.title-caption {
			position: absolute;
			right: 0;
			bottom: rem(4);
			font-weight: $font-weight-semi-bold;

			span {
				font-weight: $font-weight-bold;
			}
		}
	}

	.remove-banners {
		font-size: rem(14);
		color: $color-orange;
		align-items: center;
		cursor: pointer;
	}
</style>
