<template>
	<div class="preview-flash-sale-timer">
		<div class="timer">
			<small :style="dynamicStyleForLabel">วัน</small>
			<p :style="dynamicStyleForBox">00</p>
		</div>
		<div class="timer">
			<small :style="dynamicStyleForLabel">ชั่วโมง</small>
			<p :style="dynamicStyleForBox">00</p>
		</div>
		<div class="timer">
			<small :style="dynamicStyleForLabel">นาที</small>
			<p :style="dynamicStyleForBox">00</p>
		</div>
		<div class="timer">
			<small :style="dynamicStyleForLabel">วินาที</small>
			<p :style="dynamicStyleForBox">00</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PreviewFlashsaleTimer',

	props: {
		fontColor: {
			type: String,
			default: '#FFFFFF',
		},
		backgroundColor: {
			type: String,
			default: '#000000',
		},
	},

	computed: {
		dynamicStyleForBox() {
			return {
				'background-color': this.backgroundColor,
				color: this.fontColor,
			};
		},

		dynamicStyleForLabel() {
			return {
				color: this.backgroundColor,
			};
		},
	},
};
</script>

<style lang="scss" scoped>
.preview-flash-sale-timer {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: rem(8);

	height: rem(138);
	border: 2px solid $color-gray-300;
	border-radius: rem(4);

	.timer {
		small {
			display: block;
			text-align: center;
			margin-bottom: rem(4);
		}

		p {
			width: rem(60);
			height: rem(60);
			border-radius: rem(4);

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			margin: 0;
			font-size: rem(40);
			font-weight: 600;
		}
	}
}
</style>
