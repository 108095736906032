<template>
	<div>
		<CModal
			:show.sync="isShow"
			:close-on-backdrop="false"
			centered
		>
			<template #header-wrapper>
				<ModalHeaderWrapper
					:title="title"
					@onClose="close"
				/>
			</template>
			<template #default>
				<FormGetFlashSaleProductBySKU
					ref="form-sku"
					:sku-list="localSkuList"
					placeholder="SKU number"
					@onGetProduct="handleGet"
				>
					<template #no-item>
						<div
							class="no-item d-flex text-center"
							data-test-id="no-item"
						>
							<div class="d-block">
								<p class="no-sku font-weight-bolder color-black-45">
									No product SKU yet
								</p>
								<p class="typo-body-2 color-black-45">
									Add "Product SKU" in order to exclude promotion.
								</p>
							</div>
						</div>
					</template>
					<template #list>
						<li
							v-for="(vProduct) in $v.localSkuList.$each.$iter"
							:key="vProduct.id"
						>
							<div class="d-flex mb-2 product-info">
								<img
									:src="vProduct.thumbnail.$model"
									data-test-id="thumbnail"
									class="thumbnail-54 ml-2 mr-3"
								>
								<div class="typo-body-2 name" data-test-id="name">
									{{ vProduct.name.$model }}
									<div
										class="typo-caption color-black-45 mb-2"
										data-test-id="sku"
									>
										{{ vProduct.sku.$model }}
									</div>
								</div>
								<div class="typo-body-2 px-2 stock-qty">
									<span class="color-black-45">Stock Qty.:</span> {{ vProduct.stockAvailable.$model | numberFormat }}
								</div>
								<div class="typo-body-2 px-2 selling-price">
									<span class="color-black-45">Selling price:</span> {{ vProduct.priceSelling.$model }}
								</div>
								<CButton
									class="transparent mr-2"
									data-test-id="button-remove"
									@click="handleRemove(vProduct.sku.$model)"
								>
									<CIcon name="cil-trash" class="color-black-45" />
								</CButton>
							</div>
							<div class="d-flex mb-3 product-stock">
								<BaseInputNumber
									v-model="vProduct.flashSaleStock.$model"
									:is-valid="!vProduct.flashSaleStock.$error"
									:invalid-feedback="$t('global.error.minimumNumber', { number: 1 })"
									label="Flash sale stock*"
									prepend-text="Qty."
									text-align="right"
									placeholder="0"
									class="input-quantity"
									@input="handleInputChange"
								/>
								<BaseInputNumber
									v-model="vProduct.flashSaleManipulateSoldStock.$model"
									label="Manipulate sold stock"
									prepend-text="Qty."
									text-align="right"
									placeholder="0"
									class="input-quantity"
									@input="handleInputChange"
								/>
								<BaseInputNumber
									v-model="vProduct.flashSaleLimitUser.$model"
									label="Limit per customer"
									prepend-text="Qty."
									text-align="right"
									class="input-quantity"
									@input="handleInputChange"
								/>
								<BaseInputNumber
									v-model="vProduct.flashSaleLimitOrder.$model"
									label="Limit per order"
									prepend-text="Qty."
									text-align="right"
									class="input-quantity"
									@input="handleInputChange"
								/>
								<BaseInputNumber
									v-model="vProduct.flashSalePrice.$model"
									:is-valid="!vProduct.flashSalePrice.$error"
									:invalid-feedback="$t('global.error.minimumNumber', { number: 1 })"
									:decimal-scale="2"
									label="Flash sale price*"
									prepend-text="฿"
									text-align="right"
									placeholder="0"
									class="input-quantity"
									@input="handleInputChange"
								/>
							</div>
							<hr class="mt-4">
						</li>
					</template>
				</FormGetFlashSaleProductBySKU>
			</template>
			<template #footer>
				<BaseModalFooter
					:disabled-confirm="$v.localSkuList.$error"
					:total-select="localSkuList.length"
					@onClear="handleClear"
					@onCancel="handleCancel"
					@onConfirm="handleSubmit"
				/>
			</template>
		</CModal>
		<BaseModalConfirmDelete
			ref="modal-confirm"
			title="Leave without save?"
			description="All recently added SKUs will be discarded."
			class="modal-on-modal"
			delete-button-text="Leave without save"
			@onSuccess="handleReset"
		/>
	</div>
</template>


<script>
import { required, minValue } from 'vuelidate/lib/validators';
import cloneDeep from 'lodash/cloneDeep';
import BaseModalFooter from '@/components/BaseModalFooter.vue';
import ModalHeaderWrapper from './ModalHeaderWrapper.vue';
import FormGetFlashSaleProductBySKU from './FormGetFlashSaleProductBySKU.vue';

export default {
	name: 'ModalFlashSaleProduct',
	components: {
		ModalHeaderWrapper,
		BaseModalFooter,
		FormGetFlashSaleProductBySKU,
	},
	props: {
		title: {
			type: String,
			default: 'Add product SKU',
		},
		skuList: {
			type: Array,
			default: () => [],
		},
	},
	validations: {
		localSkuList: {
			$each: {
				sku: {},
				thumbnail: {},
				name: {},
				stockAvailable: {},
				priceSelling: {},
				flashSaleStock: {
					required,
				},
				flashSaleManipulateSoldStock: {},
				flashSaleLimitUser: {},
				flashSaleLimitOrder: {},
				flashSalePrice: {
					required,
					minValue: minValue(1),
				},
			},
		},
	},
	data() {
		return {
			isShow: false,
			localSkuList: [],
		};
	},
	methods: {
		open() {
			this.isShow = true;
			this.localSkuList = cloneDeep(this.skuList);
		},
		close() {
			this.isShow = false;
			this.$refs['form-sku'].resetState();
			this.localSkuList = [];
		},
		transformFlashProduct(products) {
			return products.map((product) => ({
				...product,
				flashSaleStock: product.flashSaleStock ?? null,
				flashSaleManipulateSoldStock: product.flashSaleManipulateSoldStock ?? null,
				flashSaleLimitUser: product.flashSaleLimitUser ?? null,
				flashSaleLimitOrder: product.flashSaleLimitOrder ?? null,
				flashSalePrice: product.flashSalePrice ?? null,
			}));
		},
		handleGet(products) {
			this.$v.$touch();
			this.localSkuList = products ? this.transformFlashProduct(products) : [];
		},
		handleRemove(sku) {
			this.localSkuList = this.localSkuList.filter((product) => product.sku !== sku);
		},
		handleCancel() {
			this.$refs['modal-confirm'].open();
		},
		handleReset() {
			this.close();
		},
		handleClear() {
			this.localSkuList = [];
		},
		handleInputChange() {
			this.$v.$touch();
		},
		handleSubmit() {
			this.isShow = false;
			this.$emit('onConfirm', this.localSkuList);
		},
	},
};
</script>

<style lang="scss" scoped>
	.no-item {
		min-height: rem(200);
		justify-content: center;
		align-items: center;
	}

	::v-deep .input-quantity {
		label {
			white-space: nowrap;
		}
	}

	::v-deep .list-item {
		min-height: rem(200);
		padding: 0;
		margin: 0;
	}

	::v-deep .modal-dialog {
		max-width: rem(800);
	}
</style>
