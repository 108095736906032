<template>
	<div :class="`${isEditMode ? 'edit-mode' : 'create-mode' }`" class="form-container">
		<div class="mb-4">
			<h4>Flash sale product list</h4>
			<p class="typo-body-2 color-black-45">
				Drag to order the product SKU display sequence. On the website, The Flash sale list will display followed by the product SKU order below.
			</p>
		</div>
		<CRow class="mt-4 mb-3">
			<CCol md="8">
				<h6 class="typo-h6">Product SKU</h6>
				<p class="typo-caption mb-0 color-black-45">Set one or more product SKUs for this flash sale</p>
			</CCol>
			<CCol md="4" class="text-right">
				<CButton
					color="secondary"
					class="btn btn-secondary"
					@click="$refs['modal-flash-sale-sku'].open()"
				>
					<CIcon v-show="!formData.flashSaleSkus.length" class="mr-2" name="cil-plus" />
					{{ formData.flashSaleSkus.length ? 'Edit' : 'Add' }} product SKU
				</CButton>
			</CCol>
		</CRow>
		<div
			v-if="formData.flashSaleSkus.length"
			class="table-wrapper"
		>
			<div class="d-flex justify-content-end product-list-header">
				<label class="label col-sku">SKUs</label>
				<label v-if="!isEditMode" class="label">Flash sale stock</label>
				<label class="label col-manipulate-stock">Manipulate sold stock</label>
				<label class="label col-limit-customer">Limit per customer</label>
				<label class="label col-limit-order">Limit per order</label>
				<label class="label col-price">Flash sale price</label>
				<label v-if="isEditMode" class="label col-sold">Sold</label>
				<label v-if="isEditMode" class="label col-remaining">Remaining</label>
			</div>
			<draggable
				:value="formData.flashSaleSkus"
				tag="ul"
				ghost-class="is-placeholder-item"
				class="product-list"
				@input="handleUpdateOrder"
			>
				<li
					v-for="item in formData.flashSaleSkus"
					:key="item.id"
					class="d-flex justify-content-end product-list-item"
				>
					<div class="d-flex product-name col-sku">
						<img
							class="thumbnail-54 mr-2"
							data-test-id="thumbnail"
							:src="item.thumbnail"
						>
						<div class="product-list-info">
							<p class="mb-0">{{ item.name }}</p>
							<p class="typo-caption color-black-45 mb-0">{{ item.sku }}</p>

							<div v-if="isEditMode" class="flash-sale-stock typo-caption color-black-45 mt-2">
								Flash sale stock: <span class="color-black-90">{{ item.flashSaleStock | numberFormat }}</span>
							</div>
						</div>
					</div>
					<div v-if="!isEditMode" class="product-stock">
						{{ item.flashSaleStock | numberFormat }}
					</div>
					<div class="product-stock col-manipulate-stock">
						{{ item.flashSaleManipulateSoldStock | numberFormat }}
					</div>
					<div class="product-stock col-limit-customer">
						{{ item.flashSaleLimitUser | numberFormat }}
					</div>
					<div class="product-stock col-limit-order">
						{{ item.flashSaleLimitOrder | numberFormat }}
					</div>
					<div class="product-stock col-price">
						{{ item.flashSalePrice | priceFormat }}
					</div>
					<div v-if="isEditMode" class="product-stock col-sold">
						{{ item.flashSaleSold | numberFormat }}
					</div>
					<div v-if="isEditMode" class="product-stock col-remaining">
						{{ item.flashSaleRemaining | numberFormat }}
					</div>
				</li>
			</draggable>
		</div>
		<BaseNoItemBanner
			v-else
			text="There is no product SKU added."
		/>
		<ModalFlashSaleProduct
			ref="modal-flash-sale-sku"
			:sku-list="formData.flashSaleSkus"
			@onConfirm="handleFlashSaleSkus"
			@update:flashSaleSkus="handleFlashSaleSkus"
		/>
	</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import draggable from 'vuedraggable';
import ModalFlashSaleProduct from '@/components/ModalFlashSaleProduct.vue';

export default {
	name: 'FlashSaleStepProductList',
	components: {
		ModalFlashSaleProduct,
		draggable,
	},
	validations: {
		formData: {
			flashSaleSkus: {
				required,
			},
		},
	},
	props: {
		defaultData: {
			type: Object,
			default: null,
		},
		isEditMode: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		const {
			flashSaleSkus = [],
		} = this.defaultData || {};

		return {
			formData: {
				flashSaleSkus,
			},
		};
	},
	watch: {
		formData: {
			deep: true,
			handler(data) {
				this.$emit(
					'update:is-valid',
					{
						valid: !this.$v.$invalid,
						data,
					},
				);
			},
		},
	},
	methods: {
		handleFlashSaleSkus(list) {
			this.formData = {
				...this.formData,
				flashSaleSkus: list,
			};
		},
		handleUpdateOrder(sortedList) {
			this.formData = {
				...this.formData,
				flashSaleSkus: sortedList,
			};
		},
	},
};
</script>

<style lang="scss" scoped>
	.form-container {
		margin-top: rem(80);

		.title-caption {
			position: absolute;
			right: 0;
			bottom: rem(4);
			font-weight: $font-weight-semi-bold;

			span {
				font-weight: $font-weight-bold;
			}
		}
	}

	.product-list {
		margin: 0;
		padding: rem(2);
		background-color: $color-gray-100;
		border-radius: rem(4);

		// .product-list-header
		&-header {
			font-size: rem(12);

			.label {
				font-size: rem(13);
				text-align: right;
				width: 15%;
				padding: 0 rem(8);
				flex-grow: 0;
				flex-shrink: 0;

				&:first-child {
					flex: 1;
					text-align: left;
					width: 40%;
				}
			}

			.col-manipulate-stock {
				width: 16%;
			}
		}

		// .product-list-item
		&-item {
			display: flex;
			justify-content: space-between;
			font-size: rem(14);
			color: $color-black-90;
			background-color: $color-white;
			padding: rem(8) 0;
			border-radius: rem(4);
			margin-bottom: rem(2);

			&:hover {
				cursor: move;
			}

			&:last-child {
				margin-bottom: 0;
			}

			> div {
				flex-grow: 0;
				flex-shrink: 0;
				padding: 0 rem(8);
			}
		}

		// .product-list-info
		&-info {
			line-height: 1.3;
		}

		.product-name {
			flex: 1;
			width: 40%;
		}

		.product-stock {
			width: 15%;
			text-align: right;
			word-break: break-all;

			&:last-child {
				word-break: keep-all;
			}
		}
	}

	.edit-mode {
		.col-manipulate-stock {
			flex-basis: rem(145);
		}

		.col-sold {
			flex-basis: rem(66);
		}

		.col-limit-customer,
		.col-price {
			flex-basis: rem(136);
		}

		.col-limit-order {
			flex-basis: rem(116);
		}

		.col-sku {
			flex-basis: rem(216);
		}

		.col-remaining {
			flex-basis: rem(96);
			padding-right: rem(16);
		}
	}

	::v-deep .list-empty {
		height: rem(272);
	}
</style>
