<template>
	<div>
		<h4 class="my-4">
			Content
		</h4>
		<div class="d-flex align-items-start form-group-switch">
			<div class="mb-2">
				<p class="description typo-helper-text color-black-45">
					This content will display on Flash sale page one display below the flash sale banner and another one display at the bottom of flash sale product list.
				</p>
			</div>
		</div>
		<div class="description-top">
			<label>Flash sale description (top)</label>
			<BaseRichTextEditor
				:value="descriptionTop"
				data-test-id="description-top"
				name="description top"
				@input="(value) => $emit('update:descriptionTop', value)"
			/>
		</div>
		<div class="description-bottom">
			<label>Flash sale description (bottom)</label>
			<BaseRichTextEditor
				:value="descriptionBottom"
				data-test-id="description-bottom"
				name="description bottom"
				@input="(value) => $emit('update:descriptionBottom', value)"
			/>
		</div>
		<hr class="mt-4">
	</div>
</template>

<script>
export default {
	name: 'FlashSaleContent',
	props: {
		descriptionBottom: {
			type: String,
			default: null,
		},
		descriptionTop: {
			type: String,
			default: null,
		},
	},
};
</script>

<style lang="scss" scoped>
	::v-deep .ql-container {
		height: rem(200);
	}
</style>
